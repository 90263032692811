





























































import { Component, Vue } from "vue-property-decorator";
import { Moment } from "moment";
import { DATE_FORMAT_REPORT, DEFAULT_DATE_FORMAT } from "@constant/date.constant";
import { FormModel } from "ant-design-vue";
import MNotificationVue from "@/mixins/MNotification.vue";
import { downloadFile } from "@/helpers/file-reader";
import { Mode } from "@/models/enums/global.enum";
import { ParamDownloadGLReport } from "@/models/interface-v2/general-journal.interface";
import { generalJournalServices } from "@/services-v2/general-journal.service";
import { ResponseAccountingAccount } from "@/models/interface-v2/accounting-account.interface";
@Component({
  name: "ReportGL",
  mixins: [
    MNotificationVue,
  ],
  components: {
    CSelectAccountingAccount: () => import(/*webpackPrefetch: true */"@/components/shared/select-accounting-account/CSelectAccountingAccount.vue"),
  }
})
export default class ReportGL extends Vue {
  DEFAULT_DATE_FORMAT = DEFAULT_DATE_FORMAT;
  form = {
    accountId: undefined as string | undefined,
    accountCode: "",
    periodFrom: null as Moment | null,
    periodTo: null as Moment | null,
  }
  rules = {
    accountId: [{ required: true, message: (): string => this.$t("lbl_validation_required_error").toString() }],
    periodFrom: [{ required: true, message: (): string => this.$t("lbl_validation_required_error").toString() }],
    periodTo: [{ required: true, message: (): string => this.$t("lbl_validation_required_error").toString() }],
  }
  loading = false;

  get isModeView(): boolean {
    return this.$route.meta.mode === Mode.VIEW;
  }

  onChangeCOA({ meta }: { meta: ResponseAccountingAccount }): void {
    this.form.accountCode = meta.code;
  }
  
  async handleDownload(): Promise<void> {
    try {
      this.loading = true;
      const params: ParamDownloadGLReport = {
        accountCode: this.form.accountCode || "",
        endDate: this.form.periodTo?.set({ hour: 0, minute: 0, second: 0 }).format(DATE_FORMAT_REPORT) || "",
        startDate: this.form.periodFrom?.set({ hour: 23, minute: 59, second: 59 }).format(DATE_FORMAT_REPORT) || "",
      };
      const file = await generalJournalServices.downloadGLReport(params);
      downloadFile(
        new Blob([file]),
        `report_gl_${this.form.periodFrom?.format(DEFAULT_DATE_FORMAT) || ""}_to_${this.form.periodTo?.format(DEFAULT_DATE_FORMAT) || ""}.xlsx`
      );
    } catch (error) {
      this.showErrorMessage("notif_download_error");
    } finally {
      this.loading = false;
    }
  }

  validateForm(): void {
    const form = this.$refs.form as FormModel;
    form.validate((valid: boolean) => {
      if (valid) {
        this.handleDownload();
        } else {
        this.showNotifValidationError();
      }
    });
  }

  handleClear(): void {
    const form = this.$refs.form as FormModel;
    form.resetFields();
  }
  handleBack(): void {
    this.$router.back();
  }
}
