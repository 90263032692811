var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    { attrs: { title: _vm.$t("lbl_report_gl") } },
    [
      _c(
        "a-form-model",
        {
          ref: "form",
          attrs: {
            model: _vm.form,
            rules: _vm.rules,
            "label-align": "left",
            "label-col": { span: 4 },
            "wrapper-col": { span: 14 }
          }
        },
        [
          _c(
            "a-form-model-item",
            { attrs: { label: _vm.$t("lbl_account"), prop: "accountId" } },
            [
              _c("CSelectAccountingAccount", {
                attrs: { "label-key": ["code", "description"] },
                on: { "on-select": _vm.onChangeCOA },
                model: {
                  value: _vm.form.accountId,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "accountId", $$v)
                  },
                  expression: "form.accountId"
                }
              })
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { prop: "periodFrom", label: _vm.$t("lbl_period_from") } },
            [
              _c("a-date-picker", {
                attrs: {
                  placeholder: _vm.$t("lbl_choose"),
                  format: _vm.DEFAULT_DATE_FORMAT,
                  "allow-clear": ""
                },
                model: {
                  value: _vm.form.periodFrom,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "periodFrom", $$v)
                  },
                  expression: "form.periodFrom"
                }
              })
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { prop: "periodTo", label: _vm.$t("lbl_period_to") } },
            [
              _c("a-date-picker", {
                attrs: {
                  placeholder: _vm.$t("lbl_choose"),
                  format: _vm.DEFAULT_DATE_FORMAT,
                  "allow-clear": ""
                },
                model: {
                  value: _vm.form.periodTo,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "periodTo", $$v)
                  },
                  expression: "form.periodTo"
                }
              })
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { "wrapper-col": { span: 14, offset: 4 } } },
            [
              _c(
                "a-space",
                [
                  _c("a-button", { on: { click: _vm.handleClear } }, [
                    _vm._v(" " + _vm._s(_vm.$t("lbl_clear")) + " ")
                  ]),
                  _c(
                    "a-button",
                    {
                      attrs: {
                        type: "primary",
                        loading: _vm.loading,
                        icon: "download"
                      },
                      on: { click: _vm.validateForm }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("lbl_download")) + " ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }